import { render, staticRenderFns } from "./DeveloperPlayground.vue?vue&type=template&id=bfe4dfee&scoped=true"
import script from "./DeveloperPlayground.vue?vue&type=script&lang=js"
export * from "./DeveloperPlayground.vue?vue&type=script&lang=js"
import style0 from "./DeveloperPlayground.vue?vue&type=style&index=0&id=bfe4dfee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe4dfee",
  null
  
)

export default component.exports